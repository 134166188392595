body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.edit-form{
    display: flex;
    justify-content: center;
    flex-direction: column;
    grid-row-gap: 50px;
    gap: 25px;
    margin-bottom: 17px;
    align-items: center;
}

.view-profile{
  display: flex;
  justify-content: center;
  padding-top: 50px;
}


.site-title{
  display:inline-flexbox;
}

.save-button{
  margin-top: 20px;
  margin-left: 80px;
}


.home-page{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;
  height: 400px;
}

.main-container{
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page{
  display: flex;
flex-direction: column;
align-items: center;
margin-top: 100px;
text-align: center;
}

.demo{
display: flex;
flex-direction: column;
align-items: center;
margin-top: 100px;
text-align: center;
}


.site-icon{
  top:10px;
}


.loading{
  display: flex;
  justify-content: center;
  padding-top: 75px;
}


.mobile-view{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 30px 30px 30px;
}

.link-input{
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.form-input{
  margin-right: 20px;
}
